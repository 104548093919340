import { SelectionChangedEvent } from "ag-grid-community";
import { ShiftEntity } from "domain/entity/Shift/ShiftEntity";
import _ from "lodash";
import { INITIAL_SHIFT_COL_DEF } from "presentation/constant/Shift/ShiftMaintenanceColumnDefinition";
import { ShiftMaintenanceConstant } from "presentation/constant/Shift/ShiftMaintenanceConstant";
import { useShiftMaintenanceVM } from "presentation/hook/Shift/useShiftMaintenanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useShiftMaintenanceTracked } from "presentation/store/Shift/ShiftMaintenanceProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { HPHTable, Loader } from "veronica-ui-component/dist/component/core";
const ShiftTablePanel: React.FC = () => {
    const [shiftState] = useShiftMaintenanceTracked();
    const shiftVM = useShiftMaintenanceVM();
    const gridRef: any = useRef(null);
    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    const [anainfoState] = useANAInfoTracked();
    const { allowUpdate } = anainfoState;
    const rightClickRef: any = createRef();
    const messageBarVM = useMessageBarVM();
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<ShiftEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        const selectedRows = e.api.getSelectedRows();
        setOnTableSelectionClicked(true);
        shiftVM.updateSelectedRows(selectedRows);
    }, [shiftVM])
    const handleAddClick = useCallback(() => {
        shiftVM.onAdd();
    }, [shiftVM])
    const handleRowDoubleClick = useCallback((entity: ShiftEntity) => {
        shiftVM.onEdit(entity);
    }, [shiftVM])
    // const handleRowDrag = useCallback((e: any, updatedRows: ShiftEntity[], movedIndex: number, overIndex: number)=>{
    //     shiftVM.onRowDrag(updatedRows);
    // },[shiftVM])

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return shiftState.selectedRows;
    }, [shiftState.selectedRows]);

    const getShowMoveCursor = useCallback(() => {
        return showMoveCursor;
    }, [showMoveCursor]);
    //for right click menu with move after end

    useEffect(() => {
        const columnDefs = (INITIAL_SHIFT_COL_DEF.slice());

        if (!shiftState.selectedRows || shiftState.selectedRows.length <= 0) {
            gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
            if (!onTableSelectionClicked) {
                gridRef.current?.gridRef.current.api?.deselectAll();
            }
        }
        changeCursor("shift-code-table", getShowMoveCursor());
    });

    useEffect(() => {
        if (!onTableSelectionClicked) return;
        gridRef?.current?.gridRef.current.api?.deselectAll();
        changeCursor("shift-code-table", getShowMoveCursor());
    }, [getShowMoveCursor, onTableSelectionClicked]);

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(shiftState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [rightClickRef, allowUpdate, shiftState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        shiftVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [shiftVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(shiftState.tableData);
        shiftVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [shiftState.tableData, shiftVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        shiftVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showWarining('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                shiftVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                shiftVM.onSearch().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    shiftVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    shiftVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [messageBarVM, shiftVM]);

    const memoTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}
                <div id="myDiv">
                    <HPHTable
                        id='shift-code-table'
                        headerLabel={ShiftMaintenanceConstant.TITLE}
                        isNewColumnSetting={true}
                        columns={INITIAL_SHIFT_COL_DEF}
                        data={allRows ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        onAddClick={handleAddClick}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={false}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: ShiftEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 125px)"
                        ref={gridRef}
                        // isRowDrag={true}
                        // onRowDragged={handleRowDrag}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        onBodyScroll={(e: any) => changeCursor("shift-code-table", getShowMoveCursor())}
                    />
                </div>
            </>
        );
    }, [allowUpdate, getSelectedRows, rightClickRef, getAllRows, onSaveMove, onMoveCancel, onRefreshRow, showMoveCursor, allRows, handleAddClick, handleSelectionChange, handleRowDoubleClick, getShowMoveCursor])

    useEffect(() => {
        if (initialAllRows && shiftState.tableData && !_.isEmpty(shiftState.tableData)) {
            setAllRows(shiftState.tableData.map((shiftEntity, index) => ({
                ...shiftEntity,
                index: index || 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, shiftState.tableData]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoTable}
    </TableWrapper>
    </>;
}
export default memo(ShiftTablePanel); 
