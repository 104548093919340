import { useShiftMaintenanceTracked } from "presentation/store/Shift/ShiftMaintenanceProvider";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import ShiftMaintenance from "./ShiftMaintenance";
import { ShiftMaintenanceFormPanel } from "./ShiftMaintenanceFormPanel";
import ShiftTableTitleBar from "./ShiftTableTitleBar";
export const ShiftMasterView: React.FC = () => { 
    const [shiftState] = useShiftMaintenanceTracked(); 
    const { isAdd, isEditable } = shiftState.masterState; 
    const { isShowEditPanel } = shiftState;
    return <> 
        <div className="main-comp-wrapper im-hide-side-form-draggable"> 
        {!(isAdd || isEditable) &&<><ShiftTableTitleBar/>
            <ShiftMaintenance/> </> }
            { 
                (isShowEditPanel &&  
                <SliderPanel 
                    isOpen={false} 
                    leftSectionWidth={"100%"} 
                    rightSectionWidth={"0%"} 
                    leftChildren={<ShiftMaintenanceFormPanel/>} 
                /> 
                ) 
            } 
        </div> 
    </> 
} 
