export const ShiftMaintenanceRequiredFieldList: string[] = [ 
    'Shift',  
]; 
export const ShiftMaintenanceConstant = {   
    SCREEN_CATEGORY: "CODE",   
    TITLE: "Shift Maintenance", 
    SHIFT_CODE: "Shift Code", 
    PRIORITY: "Priority",
    SHIFT_TYPE: "Shift Type",
    SHIFT_DAY: "Shift Day",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    AHEAD_TIME: "Ahead Time",
    EXTEND_TIME: "Extend Time",
    ACTIVE_IND: "Active Ind.",
    SHIFT_CODEIS: "Shift Code(IS)",
} 
