import { memo } from "react";
import { Sidebarheader, StyledAction } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

const ShiftTableTitleBar:React.FC = () => {
    // const [shiftState] = useShiftMaintenanceTracked(); 
    // const shiftVM = useShiftMaintenanceVM(); 
    // const [isLoading, setIsLoading] = useState(false);
    // const {updatedRows,masterState} = shiftState;
    // const { isAdd, isEditable} = masterState; 
    // const messageBarVM = useMessageBarVM();  
    
    // const handleApply = useCallback(async () => {
    //     if(_.isEmpty(updatedRows)) return;
    //     setIsLoading(true);
    //     const res = await shiftVM.onApply(updatedRows);
    //     setIsLoading(false);
    //     if(!!!res || !res.success){
    //         messageBarVM.showWarining(res.data);
    //     }else{
    //         messageBarVM.showSuccess('Apply Successful.');
    //         await shiftVM.onSearch();
    //     }
    //     setIsLoading(false);
    // }, [messageBarVM, shiftVM, updatedRows]);

    
    // const handleRefresh = useCallback(async () => {
    //     setIsLoading(true);
    //     shiftVM.onSearch().then((data) => {            
    //         setIsLoading(false)
    //     }).catch(error => {            
    //         setIsLoading(false)
    //     });
    // },[shiftVM]);

    // const isApplyDisable = () => {
    //     if(!(isAdd || isEditable) && updatedRows.length !== 0) return false
    //     return true;
    // }

    return <Sidebarheader style={{width: '100%', display:"flex", alignItems:"center"}}>
        {/* <HeaderTitle>{SCREEN_CONSTANT.TITLE}</HeaderTitle> */}
        {/* {(isLoading) && <Loader Indicator="Spinner" size="Medium" /> } */}
        <StyledAction className="tm-animated-wrapper">
            {/* <IconButton fileName='Icon-reload' disabled={(isAdd || isEditable)} size='medium' toolTipText={'Refresh'} onClick={handleRefresh}/>
            <div className="add-seperator"/> */}
            {/* <HPHButton label={WorkspaceConstant.Common.BUTTON_APPLY} size={"Small"} theme={"Primary"}  disabled={isApplyDisable()} onClick={handleApply} /> */}
        </StyledAction>
    </Sidebarheader>
}

export default memo(ShiftTableTitleBar);